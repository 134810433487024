import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import App from '../components/App/App';

const NotFoundPage = () => {
  // const ttRoutes = [
  //   //'overview',
  //   //'download',
  //   //'prices',
  //   //'personal-licenses',
  //   //'how-to-setup/crypto/binance',
  //   //'download/TigerTradeSetup.exe',
  //   //'how-to-setup/crypto/bitmex',
  //   //'terms-of-service',
  //   //'contacts',
  //   //'end-user-license-agreement',
  //   //'how-to-setup/crypto/bybit',
  //   //'how-to-setup/brokers/quik',
  //   //'how-to-setup/brokers/metatrader',
  //   //'download/previous-versions',
  //   //'how-to-setup/brokers/gaincapital',
  //   //'privacy-policy',
  //   //'how-to-setup/brokers/transaq',
  //   //'how-to-setup/crypto/bitfinex',
  //   //'how-to-setup/brokers/interactivebrokers',
  //   //'how-to-setup/crypto/deribit',
  //   //'how-to-setup/brokers/smartcom',
  //   //'how-to-setup/crypto/hitbtc',
  //   //'how-to-setup/brokers/rithmic',
  // ];
  //const router = useRouter();

  // useEffect(() => {
  //   if (ttRoutes.includes(cutPageName(router.asPath))) {
  //     router.push(`${router?.locale}/terminal${router.asPath}`);
  //   }
  // }, [router]);

  return (
    <App>
      <div className="content">
        <h1>404 - page not found</h1>
      </div>
    </App>
  );
};

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common'])),
  },
  revalidate: 60 * 100,
});

export default NotFoundPage;

// `/${locale}/${route.destination}`
